import { useTranslation } from 'next-i18next';
import { useLocalStorage } from 'react-use';
import { useContext, useState } from 'react';
import { useRouter } from 'next/router';
import { requestRefresh } from '@lib/auth';
import { CERTIFICATION_ERROR_MESSAGE } from '@lib/error/certification-error';
import * as certification from '@util/certification';
import { UserInfoStatusEnum } from 'src/provider';
import { HistoryContext } from 'src/provider/HistoryProvider';
import { LANGUAGE_CODE } from 'src/constants';
import { useUserInfo } from './userInfo';
import { checkCerification } from '@api/payment/payment';
import { LanguageCodeEnum } from 'src/generated/graphql';

export const useAdultCertification = () => {
  const router = useRouter();
  const { i18n, t } = useTranslation(['adult']);
  const { history } = useContext(HistoryContext);
  const { status, userInfo, updateUserInfo, goLoginIfNecessary, isAuthenticated } = useUserInfo();
  const [authToken, setAuthToken, removeAuthToken] = useLocalStorage('acon-auth-token', '');

  const [isInprogressCertification, setIsInprogressCertification] = useState(false);

  /**
   * 로그인 및 토큰 리프레시 당시의 성인여부 값
   * 최신상태의 값을 구하려면 아래 checkRefreshAdultCertification 함수를 사용
   */
  // [todo]: checkCerification 사용해서 체크하도록 변경해야 함.
  const isAdult = userInfo?.isAdult;

  const refreshToken = (language?: string) => {
    if (!isAuthenticated) return false;
    return new Promise(async (resolve, reject) => {
      try {
        requestRefresh(
          {
            authToken: window?.localStorage['acon-auth-token']?.replace(/"/gi, '') || authToken,
            setAuthToken,
            removeAuthToken,
          },
          language ? language : i18n.language,
          (accessToken) => {
            const userPayload = updateUserInfo(accessToken);
            resolve(Boolean(userPayload?.isAdult));
          },
        );
      } catch (err) {
        reject(err);
      }
    });
  };

  /**
   * 성인인증 여부 갱신 후 성인인증 여부 반환
   * 현재 몰별 독립적으로 인증여부를 검사하므로, 몰별로 갱신된 값을 검사하는데 사용 중
   */
  const checkRefreshAdultCertification = (language?: string) => {
    if (!isAuthenticated) return false;
    return new Promise(async (resolve, reject) => {
      try {
        if (isAdult) {
          const result = await checkCerification(i18n.language as LanguageCodeEnum);
          if (result && result?.status === 'confirmed') return resolve(true);
        }
      } catch {}

      try {
        const refreshTokenResult = await refreshToken(language);
        resolve(refreshTokenResult);
      } catch (err) {
        reject(err);
      }
    });
  };

  const adultCertification = async (props: certification.IAdultCertificationProps) => {
    if (goLoginIfNecessary() !== UserInfoStatusEnum.authenticated) return false;

    setIsInprogressCertification(true);
    const result = await certification.adultCertification(props);
    if (!result) return false;

    if (result.status === 'rejected') {
      alert(t(CERTIFICATION_ERROR_MESSAGE.ADULT_REJECT));
      setIsInprogressCertification(false);

      // 반려 시 최근 페이지로 이동
      let recentPage = (history || []).find((page) => !['/adult', '/users/join', '/users/login'].some((x) => page.includes(x)));

      if (
        recentPage &&
        Object.values(LANGUAGE_CODE)
          .map((lang) => `/${lang}`)
          .includes(recentPage.substr(0, 3))
      ) {
        recentPage = recentPage.substr(3, recentPage.length);
        router.push(recentPage || '/');
        return;
      }

      router.push('/');
      return false;
    }

    if (result.status === 'verifying') {
      alert(t(CERTIFICATION_ERROR_MESSAGE.ADULT_CANCEL));
      setIsInprogressCertification(false);
      return false;
    }

    const checkedAdultCertification = await refreshToken();
    setIsInprogressCertification(false);

    return checkedAdultCertification;
  };

  return {
    isAdult: Boolean(isAdult && status === UserInfoStatusEnum.authenticated),
    isInprogressCertification,
    checkRefreshAdultCertification,
    adultCertification,
  };
};
