import React, { useEffect } from 'react';
import { useTranslation } from 'next-i18next';
import { isMobile } from 'react-device-detect';
import { useRouter } from 'next/router';
import { SubGNBTab } from '@components/header/tab';
import { CustomLink, CustomText } from '@components/ui';
import GnbHome from 'src/assets/GnbHome';
import { HoverItem } from '@components/wrapper';
import { PATH_PHOTO_REVIEW, PATH_FUNDING } from 'src/routes/paths';
import CategoryIcon from 'src/assets/icons/CategoryIcon';
import { Category } from './category';
import * as Styled from './styled';
import { LANGUAGE_CODE } from 'src/constants';

interface props {
  isSticky: boolean;
  isShowExplore: boolean;
  setIsShowExplore: React.Dispatch<any>;
}

/* eslint-disable @typescript-eslint/no-unused-vars */
export const SubGnb: React.FC<props> = ({ isSticky, isShowExplore, setIsShowExplore }) => {
  const router = useRouter();
  // 번역 도구
  const { t, i18n } = useTranslation(['game', 'menu']);

  // 3D모델 찾기 버튼 클릭 이벤트 처리기 메소드
  const handleExploreButtonClick = () => {
    if (!isMobile) {
      // 모달창 표시
      setIsShowExplore(true);
      // 종료
    }
  };

  // 카테고리 닫기
  const handleCategoryClose = () => {
    setIsShowExplore(false);
  };

  // 카테고리 컴포넌트 제거 메소드
  const handleCategoryRemoveClick = () => {
    // 3D 모델찾기 모달 숨김
    location.hash = '';
    handleCategoryClose();
  };

  // hash 변경 감지
  useEffect(() => {
    // URL에 #category가 있을 경우 모델 표시
    if (!isShowExplore && location.hash === '#category') {
      // 3D 모델찾기 모달 표시
      setIsShowExplore(true);
    }
  }, [typeof location !== 'undefined' && location.hash]);

  return (
    <Styled.Wrapper>
      <Styled.Container>
        <HoverItem className={'h-full'}>
          <Styled.CategoryButton onClick={handleExploreButtonClick} data-mixpanel-action="click" data-mixpanel-evt="GNB Click, GNB Click_category" data-mixpanel-name="category">
            <SubGNBTab
              // prefetch 위한 코드
              // #category로 url 이동시킬 때마다 브라우저 히스토리가 남아서, mobile이 아닌 경우 href={null} 넘겨주도록 처리
              href={!isMobile ? null : `/${i18n.language}/category`}
              iconComponent={<CategoryIcon size={24} />}
              iconMargin={8}
              className="gnb__category"
              displayName={t('menu:subgnb.findModel')}
            />
          </Styled.CategoryButton>
        </HoverItem>
        <Category isShow={isShowExplore} isMobile={false} onClick={handleCategoryRemoveClick} onClose={handleCategoryClose} />

        <HoverItem className={'h-full'}>
          <Styled.CommonLi isActive={router.pathname === '/event'}>
            <SubGNBTab className="gnb__event" displayName={t('menu:subgnb.event')} href="/event" />
          </Styled.CommonLi>
        </HoverItem>

        <HoverItem className={'h-full'}>
          <Styled.CommonLi isActive={router.pathname === '/best'}>
            <SubGNBTab className="gnb__best" displayName={t('menu:subgnb.best')} href="/best" />
          </Styled.CommonLi>
        </HoverItem>

        <HoverItem className={'h-full'}>
          <Styled.CommonLi isActive={router.pathname === '/new'}>
            <SubGNBTab className="gnb__new" displayName={t('menu:subgnb.new')} href="/new" />
          </Styled.CommonLi>
        </HoverItem>

        <HoverItem className={'h-full'}>
          <Styled.CommonLi isActive={router.pathname === '/event/sale'}>
            <SubGNBTab className="gnb__sale" textStyle={{ whiteSpace: 'pre' }} isBadge={false} displayName={t('menu:subgnb.sale')} href="/event/sale" />
          </Styled.CommonLi>
        </HoverItem>
        <HoverItem className={'h-full'}>
          <Styled.CommonLi isActive={router.pathname === PATH_PHOTO_REVIEW.root}>
            <SubGNBTab displayName={t('menu:subgnb.photoreview')} className="gnb__photoreviews" href={PATH_PHOTO_REVIEW.root} isBadge={false} />
          </Styled.CommonLi>
        </HoverItem>

        <HoverItem className={'h-full'}>
          <Styled.CommonLi isActive={router.asPath.indexOf(PATH_FUNDING.root) > -1}>
            <SubGNBTab isBadge={new Date() <= new Date('2022-12-31T23:59:59+09:00')} displayName={t('menu:subgnb.funding')} className="gnb__funding" href={PATH_FUNDING.root} />
          </Styled.CommonLi>
        </HoverItem>

        {i18n.language !== LANGUAGE_CODE.CN && (
          <HoverItem className={'h-full'}>
            <Styled.CommonLi isActive={router.pathname === '/adult'}>
              <SubGNBTab displayName={t('menu:subgnb.adult')} className="gnb__adult" href={'/adult'} isBadge={false} />
            </Styled.CommonLi>
          </HoverItem>
        )}

        <HoverItem className={'h-full'}>
          <CustomLink className={'menu__upload gnb__upload'} href={'/intro/3d-creator'}>
            <div className={'flex items-center'}>
              <GnbHome className={'mr-1'} />
              <CustomText weight={600} color={'#313135'} lineHeight={'20px'}>
                {t('game:upload')}
              </CustomText>
            </div>
          </CustomLink>
        </HoverItem>
      </Styled.Container>
    </Styled.Wrapper>
  );
};
